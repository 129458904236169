import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { BUTTON_INLINE, BUTTON_SMALL, Button } from "@madecomfy/webooi";

import { CompanyList } from "Containers/CompanyList/CompanyList";
import { H4 } from "Components/Typography";
import { DeletePopup } from "./DeletePopup";
import { linkCompany } from "Actions/users/userCreate";

const Wrapper = styled.div`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.charcoalLight50};
  color: ${({ theme }) => theme.charcoal};
`;

const List = styled.div`
  border-radius: 4px;
  border: 2px solid ${({ theme }) => theme.charcoalLight50};
  margin-bottom: 16px;
`;

const Item = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.navyLight50};
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  margin: 4px;
  padding: 4px 8px;
`;

const CompanySelector = ({
  ownedCompanies,
  userDetails,
  setUserCompanies,
  setOwnedCompanies,
}: {
  ownedCompanies: { [key: string]: any }[];
  userDetails?: { [key: string]: any };
  setUserCompanies: (...args: any) => any;
  setOwnedCompanies: (...args: any) => any;
}) => {
  const [companies, setCompanies] = useState<any[]>([]);
  const [deleteId, setDeleteId] = useState<number | string | null>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleRemoveAssignedCompany = async (companyId: number | string) => {
    try {
      setLoading(true);
      const userCode = userDetails?.code;
      const newOwnedCompanies = ownedCompanies?.filter(
        (company) => company?.id !== companyId,
      );
      const newOwnedCompanyIds = newOwnedCompanies?.map(
        (company) => company?.id,
      );
      await linkCompany(userCode, true, newOwnedCompanyIds);
      setOwnedCompanies(newOwnedCompanies);
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.warn(err);
    } finally {
      setDeleteId(null);
      setLoading(false);
    }
  };

  const renderExisting = () => {
    const removeExistCompany = (companyId: any) => {
      setDeleteId(companyId);
    };
    if (ownedCompanies?.length) {
      return (
        <>
          <H4>MadeComfy Pro Partners already assigned to this user:</H4>
          <List data-test="owned-companies">
            {ownedCompanies?.map((company: any) => {
              return (
                <Item key={`existing-${company?.id}`}>
                  {company?.companyName}
                  <Button
                    label="Remove"
                    my={0}
                    onClick={() => removeExistCompany(company?.id)}
                    styling={BUTTON_INLINE}
                    size={BUTTON_SMALL}
                  />
                </Item>
              );
            })}
          </List>
        </>
      );
    }
    return null;
  };

  const renderSelect = () => {
    const addCompany = (event: any, company: any) => {
      const error = ownedCompanies?.find(({ id }) => id === company.id);
      if (error) {
        return alert("User is already assigned to this MadeComfy Pro Partner");
      }

      const doubleClick = companies.find(({ id }) => id === company.id);
      if (doubleClick) {
        // user has selected a company twice, remove it on second click
        removeCompany(company);
        return;
      }
      setCompanies([...companies, company]);
    };

    const removeCompany = (company: any) => {
      const newCompanies = companies.filter(({ id }) => id !== company.id);
      setCompanies(newCompanies);
    };

    useEffect(() => {
      setUserCompanies(companies);
    }, [JSON.stringify(companies)]);
    return (
      <>
        <H4>Assign additional MadeComfy Pro Partners to this user:</H4>
        <List data-test="pending-companies">
          {companies?.length ? (
            companies.map((company: any) => {
              return (
                <Item key={company.id}>
                  {company?.companyName}
                  <Button
                    label="Remove"
                    my={0}
                    onClick={() => removeCompany(company)}
                    styling={BUTTON_INLINE}
                    size={BUTTON_SMALL}
                  />
                </Item>
              );
            })
          ) : (
            <Item>Select a company below...</Item>
          )}
        </List>
        <Wrapper>
          <CompanyList
            handleClick={addCompany}
            ownedCompanies={ownedCompanies}
            initialEmpty
          />
        </Wrapper>
      </>
    );
  };
  return (
    <div>
      {renderExisting()}
      {renderSelect()}
      <DeletePopup
        id={deleteId}
        loading={loading}
        title="Remove company"
        content="Are you sure you want to remove this company?"
        handleClose={() => setDeleteId(null)}
        handleDelete={handleRemoveAssignedCompany}
      />
    </div>
  );
};

export default CompanySelector;
