import get from "lodash/get";

import { generateErrorHandler, getJSON } from "Utils/Network";
import { API_URL } from "Config";
import { EDIT_USER } from "Constants/actionTypes";
import { logException } from "ThirdParty";
import { RESTORE as mode } from "Constants/crud";

const handleError = generateErrorHandler(EDIT_USER.ERROR);

export function userRestore(userCode: string | number) {
  return async (dispatch: (...args: any) => any) => {
    try {
      dispatch({ mode, type: EDIT_USER.PENDING, status: true });
      const json = await getJSON(
        `${API_URL}v3/users/${userCode}?include=properties,properties.address,companies`,
      );
      const ownedProperties = [] as any[];
      const propertyAddresses = [{} as {[key: string]: any}];
      const ownedCompanies = [] as any[];
      json?.included?.forEach(({ id, type, attributes }: {[key: string]: any}) => {
        switch (type) {
          case "property":
            ownedProperties.push({
              id,
              ...attributes,
              address: propertyAddresses[id],
            });
            break;
          case "property-address":
            propertyAddresses[id] = attributes;
            break;
          case "company":
            ownedCompanies.push({
              id,
              ...attributes,
            });
            break;
          default:
            throw new Error("invalid included data type:" + type);
        }
      });
      const user = get(json, "data.attributes");
      dispatch({
        mode,
        type: EDIT_USER.SUCCESS,
        user,
        ownedProperties,
        ownedCompanies,
      });
    } catch (err: any) {
      logException("userRestore", EDIT_USER);
      dispatch(handleError(err));
    }
  };
}

export function userMeRestore() {
  return userRestore("me");
}

export function resetGet() {
  return (dispatch: (...args: any) => any) => {
    dispatch({ mode, type: EDIT_USER.RESET });
  };
}
